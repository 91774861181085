.toggleswitch{
	position:relative;
	margin:0;
	margin-top:30px;
	margin-bottom:10px;
	padding-top:5px;
}

.toggleswitch .top {
	top:-35px;
}

.toggleswitch .bottom {
	top:45px;
}

.toggleswitch.on .collar {
	-webkit-transform: scaleY(-1);
	-moz-transform: scaleY(-1);
	transform: scaleY(-1);
}

.hexnut { 
	width: 40px; 
	height: 22px; 
	background: #ccc; 

	background:linear-gradient(
      to right, 
      #eee 5%, #bbb 95%
    );
	position: relative; 
}

.hexnut:before { 
	content: ""; 
	position: absolute; 
	top: -10px; 
	left: 0; 
	width: 0; 
	height: 0; 
	border-left: 20px solid transparent; 
	border-right: 20px solid transparent; 
	border-bottom: 10px solid #eee; 
} 

.hexnut:after { 
	content: ""; 
	position: absolute; 
	bottom: -10px; 
	left: 0; 
	width: 0; 
	height: 0; 
	border-left: 20px solid transparent; 
	border-right: 20px solid transparent; 
	border-top: 10px solid #ccc; 
}

.collar {
	position:relative;
	top:-5px;
	left:5px;
	width:20px;
	height:20px;
	border:5px #999 solid;
	background:#333;
	border-radius:50%;
	z-index:2;
}

.bat { 
	display:block; 
	position:absolute;
	top:4px; left:3px;
	width: 12px; 
	height: 30px;  
	background-color: #aaa; 
	border-right:1px #666 solid;
	border-bottom:2px #fff solid;
	border-left:2px #ccc solid;
	border-radius: 50% 50% 50% 50% / 60% 60% 20% 20% ; 
} 

@media (max-width: 915px) {
	.toggleswitch .top {
		top:-30px;
	}

	.toggleswitch .bottom {
		top:40px;
	}
}


