body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

header {
    font-size: 1.5em;
    font-style: bold;
    margin-top:30px;
    margin-left:10%;
}

ul {
	list-style-type: none;
	margin:0;
	padding:0;
}

form {
    margin:0;
    padding:0;
}

label {
	font:1em 'PT Sans', sans-serif;
	color:#244;
	text-shadow: #acc 1px 1px 0;
	width:100%;
	text-align:center;
	position:absolute;
	left:0;
	top:20px;
}


.group:after {
  content: "";
  display: table;
  clear: both;
}

#swarmatron {
	text-align:center;
    width:90%;
	max-width:950px;
    margin: 60px auto;
    position: absolute;
    top: 80px;
    left: 50%;
	display:none;
}

.explanation {
    text-align: left;
    color: #fff;
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
}

html.webaudio #swarmatron {
    display:block;
}

.close {
    position: absolute;
    z-index: 10;
    top: -15px;
    right: -15px;
    width: 35px;
    height: 35px;
    background: url(../img/close.png);
    background-size: contain;
}

.close:hover {
    background: url(../img/close_over.png);
    background-size: contain;
    cursor: pointer;
}

#mainpanel {
	position:relative;
	margin:auto;
	margin-bottom:0;
	text-align: center;
	background:#88aaaa;
	background:linear-gradient(
      to right,
      #8aa 99.9%, #333 0.1%
    );
	padding:20px;
	border-top: 10px #999 ridge;
	border-left: 10px #999 ridge;
	border-right: 10px #aaa ridge;
	border-radius:10px 10px 0 0;
}

#ribbonpanel {
	position:relative;
	margin:auto;
	margin-top:0;
	text-align: center;
	background:#88aaaa;
	background:linear-gradient(
      to right,
      #8aa 99.9%, #333 0.1%
    );
	padding:20px;
	border-bottom: 10px #999 ridge;
	border-left: 10px #999 ridge;
	border-right: 10px #aaa ridge;
	border-top:1px #366 solid;
	border-radius:0 0 10px 10px;
}

#ribbonholder {
	margin:auto;
	text-align:center;
	width:90%;
}


.mainknobrow, .mainswitchrow {
	display:inline-block;
	float:none;
	margin:auto;
	clear:both;
	margin-top:20px;
    transition:margin 0.5s;
}

.mainknobrow li {
	float:left;
	margin:10px 40px;
    transition:margin 0.5s;
}

.mainswitchrow li {
	float:left;
	margin:10px 15px;
    transition:margin 0.5s;
}

.mainswitchrow li.knob, .mainswitchrow li.toggleswitch {
	margin-top:15px;
    transition:margin-top 0.5s;
}

.mainswitchrow li.knob {
	margin-right:50px;
    transition:margin-top 0.5s;
}

.mainswitchrow li.toggleswitch {
	margin-left:50px;
    transition:margin-left 0.5s;
}

.presetmanager {
    width:85%;
    text-align: left;
    position:relative;
    margin:auto;
}

.presetmanager button {
    position:relative;
    background:#8aa;
    border:2px #acc solid;
    border-bottom-color:#647878;
    border-right-color:#647878;
    border-radius:5px;
    font:0.75em 'PT Sans', sans-serif;
    color:#022;
    text-shadow: #acc 1px 1px 0;
    line-height:16px;
    margin-left:10px;
    outline:none;

}

.presetmanager button:active {
    border:2px #acc solid;
    border-top-color:#647878;
    border-left-color:#647878;
    top:1px;left:1px;
}

.presetmanager button:disabled {
    border:1px #699 solid;
    color:#566;
}

.presetmanager input {
    background:#609090;
    background-image: radial-gradient(#699 0%, #366 75%);
    border:none;
    border:1px #7aa solid;
    border-top-color:#3c5656;
    border-left-color:#3c5656;
    border-radius:4px;
    color:#fa3;
    font-size:0.75em;
    line-height:18px;
    height:22px;
    margin-left:10px;
    outline:none;
    padding:0 5px;
}


select {
    -moz-appearance:window;
    background:#609090;
    background-image: radial-gradient(#699 0%, #366 75%);
    border:none;
    border:1px #7aa solid;
    border-top-color:#3c5656;
    border-left-color:#3c5656;
    border-radius:4px;
    color:#fa3;
    font-size:0.8em;
    text-shadow: #853 1px 2px 1px;
    line-height: 24px;
    height:24px;
    outline:none;
}


.savepreset{
    display:inline-block;
}

.savepreset span {
    padding-right:5px;
}


@media (max-width: 1050px) {
     .mainswitchrow li.knob {
        margin-right:35px;
    }

     .mainswitchrow li.toggleswitch {
        margin-left:35px;
    }

     .mainswitchrow li {
        margin-left:10px;
        margin-right:10px;
    }

     .mainknobrow li {
        margin-left:30px;
        margin-right:30px;
    }
 }

 @media (max-width: 840px) {
    #swarmatron {
        margin: 60px auto;
    }

    #mainpanel {
        padding-bottom:15px;
    }

    .mainknobrow {
        margin-top:10px;
    }

    .mainswitchrow {
        margin-top:20px;
    }

     .mainswitchrow li.knob {
        margin-right:20px;
    }

     .mainswitchrow li.toggleswitch {
        margin-left:20px;
    }

     .mainswitchrow li {
        margin-left:5px;
        margin-right:5px;
    }

     .mainknobrow li {
        margin-left:20px;
        margin-right:20px;
    }
 }

 @media (max-width: 690px) {
     .mainswitchrow li.knob {
        margin-right:10px;
    }

     .mainswitchrow li.toggleswitch {
        margin-left:10px;
    }

     .mainswitchrow li {
        margin-left:2px;
        margin-right:2px;
    }

     .mainknobrow li {
        margin-left:15px;
        margin-right:15px;
    }
 }

 @media (max-width:620px){
 	#swarmatron {
        width:560px;
    }

    #mainpanel, #ribbonpanel {
        padding:10px;
    }

    #mainpanel {
        padding-bottom:20px;
    }

    label.voices {
        top:4px;
    }

    .mainknobrow, .mainswitchrow {
        margin-top:7px;
    }

     .mainknobrow li {
        margin-top: 5px;
        margin-bottom:5px;
    }

     .mainswitchrow li.knob {
        margin-top:10px;
    }
 }
