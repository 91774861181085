
.record {
	position:absolute;
	bottom:60px;
	right:60px;
	display:none;
}

.save {
	position: absolute;
	z-index: 1;
	background: radial-gradient(#699 0%, #366 75%);
    border: 1px #699 solid;
    border-right-color: #8bb;
    border-bottom-color: #8bb;
    border-radius: 4px;
	top: 7px;
	left: -80px;
	width: 160px;
	height: 120px;

}

.save label {
	color: #fa3;
	text-shadow: #853 1px 2px 1px;
}

.save label.sublabel {
	font-size: 14px;
	margin-top: -5px;
}

.save-as-enter {
	opacity: 0;
	transform: scale(0.1);
}

.save-as-enter-active {
	opacity: 1;
	transform: scale(1);
	transition: all 300ms ease-out;
}

.save-as-exit {
	opacity: 1;
	background: radial-gradient(circle, #ff0,#9f9,#aaa);
}

.save-as-exit-active {
	opacity: 0;
	transition: all 900ms;
}

html.webworkers.adownload .record {
	display:block;
}

.record label {
	position:relative;
	display:block;
	top:0;
	margin:10px 0;
}

.recswitch {
	width:30px;
	height:30px;
	margin:auto;
	margin-bottom:10px;
	background:#8aa;
	background-image: radial-gradient(farthest-corner at 15px 15px , #9bb 0%, #699 95%);
	border:1px #acc outset;
	border-radius:5px;
	cursor:pointer;
}

.recswitch:hover{
	background-image: radial-gradient(farthest-corner at 15px 15px , #add 0%, #588 95%);
}

.recswitch.pushed {
	border:1px #cbb inset;
}

.recswitch.on {
	background:radial-gradient(circle, #ff0,#f99,#aaa);
	transition-property: background;
    transition-duration: 5s;
    transition-timing-function: linear;
}

.saveswitch,  .record .saveswitch.disabled {
	z-index: 2;
	display:block;
	margin: 15px auto 5px;
	width:20px;
	height:20px;
	background:#ccc;
	background-image: radial-gradient(circle , #ddd 40%, #999 75%);

	border-radius:20px;
	border-top:3px #ccc solid;
	border-right:3px #bbb solid;
	border-bottom:3px #bbb solid;
	border-left:3px #ccc solid;
	-webkit-transition:width 0.5s;
	transition:width 0.5s;
	cursor:pointer;
}

.record .saveswitch.disabled {
	background-image: radial-gradient(circle , #aaa 40%, #999 75%)
}

.saveswitch:hover {
	background:#ccc;
	background-image: radial-gradient(circle , #ddf 40%, #666 75%);

	border-top:3px #bbb solid;
	border-right:3px #ccc solid;
	border-bottom:3px #ccc solid;
	border-left:3px #bbb solid;
}

.saveswitch:active {
	background-image: radial-gradient(circle , #fff 40%, #333 75%);

	border-top:3px #bbb solid;
	border-right:3px #ccc solid;
	border-bottom:3px #ccc solid;
	border-left:3px #bbb solid;
}

@media (max-width: 875px) {
	html.webworkers.adownload .record {
		display:none;
	}
}
