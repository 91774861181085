body {
  background: #000 url(../img/background_1.jpg) top center no-repeat;
  background-size: cover;
}

a {
  color: #e7712a;
  text-decoration: none;
}

a:hover,
a:active {
  color: #2a95e7;
}

#home {
  padding: 2% 5%;
  position: relative;
}

#swarmthumb {
  width: 272px;
  height: 152px;
  background: url(../img/swarm.png);
  flex-shrink: 0;
  margin-top: 1em;
}

#swarmthumb:hover {
  cursor: pointer;
  background: url(../img/swarm_on.png);
}

#ckthumb {
  width: 147px;
  height: 146px;
  background: url(../img/ckid.png);
  flex-shrink: 0;
  margin-top: 1em;
}

#ckthumb:hover {
  background: url(../img/ckid_on.png);
}

.header,
.main {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
}

.header-left,
.header-right {
  font-family: 'Audiowide', cursive;
  color: #2a95e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-left {
  font-size: 60px;
  text-transform: uppercase;
}

.header-right {
  font-size: 42px;
}

.main {
  margin-top: 80px;
}

.main-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 30px;
  margin-right: 80px;
}

.main-left p.first {
  margin: 5px 0 0;
}

.bottom-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -40px;
}

.bottom-nav ul {
  display: flex;
  justify-content: space-between;
}

.bottom-nav ul li {
  margin-right: 30px;
  font-size: 24px;
  color: #e7712a;
}


.main-right img {
  margin-top: 1em;
}

.projects-header {
  font-size: 36px;
  color: #e7712a;
}

.project-block {
  margin-top: 30px;
}

.project-block.first {
  margin-top: 15px;
}

.project-title {
  font-size: 24px;
  font-family: 'Audiowide', cursive;
  color: #2a95e7;
}

.project-body {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.project-text {
  /* padding-left: 30px; */
}

/*swarmatron animation*/
.swarm-home-enter {
  opacity: 0;
  transform: scale(0.1) translateX(-50%);
}

.swarm-home-enter-active {
  opacity: 1;
  transform: scale(1) translateX(-50%);
  transition: all 300ms ease-out;
}

.swarm-home-enter-done {
  transform: translateX(-50%);
}

.swarm-home-exit {
  opacity: 1;
  transform: translateX(-50%);
}

.swarm-home-exit-active {
  opacity: 0;
  transform: scale(0.1) translateX(-50%);
  transition: all 300ms;
}

.home-enter {
  opacity: 0;
}

.home-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
}

.home-exit {
  opacity: 1;
}

.home-exit-active {
  opacity: 0;
  transition: all 300ms;
}



@media(max-width: 1200px) {
  .project-body.widethumb {
    flex-direction: column;
  }

  .project-body.widethumb .project-text {
    padding-left: 0;
  }

  .header-left {
    font-size: 48px;
    text-transform: uppercase;
  }

  .header-right {
    font-size: 36px;
  }
}

@media(max-width: 1000px) {

  .header,
  .main {
    flex-direction: column;
  }

  .main {
    margin-top: 40px;
  }

  .main-left {
    font-size: 24px;
  }

  .projects-header {
    font-size: 30px;
    margin-top: 30px;
  }

  .project-body.widethumb {
    flex-direction: row;
  }

  .project-body.widethumb .project-text {
    padding-left: 30px;
  }

  .bottom-nav {
    margin-top: 30px;
  }

  .bottom-nav ul li {
    font-size: 20px;
  }
}

@media(max-width: 480px) {
  .header-left {
    font-size: 36px;
    text-transform: uppercase;
  }

  .header-right {
    font-size: 24px;
  }

  .main-left {
    font-size: 18px;
  }

  .projects-header {
    font-size: 24px;
  }

  .project-title {
    font-size: 20px;
  }

  .project-body {
    font-size: 16px;
  }

  .project-body.widethumb,
  .project-body {
    flex-direction: column;
  }

  .project-body.widethumb .project-text,
  .project-body .project-text {
    padding-left: 0;
  }

  .bottom-nav {
    align-items: stretch;
  }
}